<template>
    <div class="block">
        <el-timeline v-if="visible">
            <el-timeline-item
                v-for="(activity, index) in activities"
                :key="index"
                :icon="activity.icon"
                :type="activity.type"
                :color="activity.color"
                :size="activity.size"
                :timestamp="activity.timestamp">
                <span style="color: #bbbbbb">{{activity.content}}</span>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            activities: [{
                content: '填写申请',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_apply',
            }, {
                content: '项目责任部门审核——经办人审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_first_review',
            }, {
                content: '项目责任部门审核——负责人审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_second_review',
            }, {
                content: '项目责任部门审核——分管副总审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_third_review',
            }, {
                content: '市场部——经办人审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_fourth_review',
            }, {
                content: '市场部——分管营销副总审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_fifth_review',
            }, {
                content: '外委运维单位——值班负责人审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_sixth_review',
            }, {
                content: '发展计划部——生产经办人审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_seventh_review',
            }, {
                content: '发展计划部——调度经办人审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_eighth_review',
            }, {
                content: '发展计划部——负责人审',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_ninth_review',
            }, {
                content: '分管生产副总审核',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_tenth_review',
            }, {
                content: '回执',
                // timestamp: '2018-04-12 20:46',
                size: 'large',
                code: 'elec_overhaul_unplanned_receipt',
            }],
        };
    },
    methods: {
        init(item) {
            let activityIndexMap = this.initActivityIndexMap();
            let curNode = item.curNode;
            let curCode = curNode.code;
            let index = activityIndexMap.get(curCode);
            //设置节点颜色
            this.setColor(index, activityIndexMap);
            //设置完成时间
            this.setTime(item);
            debugger;
            this.$nextTick(() => {
                this.visible = true;
            });
        },
        initActivityIndexMap() {
            let activityIndexMap = new Map();
            let index = 0;
            //填写申请
            activityIndexMap.set('elec_overhaul_unplanned_apply', index++);
            //项目责任部门审核——经办人审核
            activityIndexMap.set('elec_overhaul_unplanned_first_review', index++);
            //项目责任部门审核——负责人审核
            activityIndexMap.set('elec_overhaul_unplanned_second_review', index++);
            //项目责任部门审核——分管副总审核
            activityIndexMap.set('elec_overhaul_unplanned_third_review', index++);
            //市场部——经办人审核
            activityIndexMap.set('elec_overhaul_unplanned_fourth_review', index++);
            //市场部——分管营销副总审核
            activityIndexMap.set('elec_overhaul_unplanned_fifth_review', index++);
            //外委运维单位——值班负责人审核
            activityIndexMap.set('elec_overhaul_unplanned_sixth_review', index++);
            //发展计划部——生产经办人审核
            activityIndexMap.set('elec_overhaul_unplanned_seventh_review', index++);
            //发展计划部——调度经办人审核
            activityIndexMap.set('elec_overhaul_unplanned_eighth_review', index++);
            //发展计划部——负责人审
            activityIndexMap.set('elec_overhaul_unplanned_ninth_review', index++);
            //分管生产副总审核
            activityIndexMap.set('elec_overhaul_unplanned_tenth_review', index++);
            //执行中
            activityIndexMap.set('elec_overhaul_unplanned_receipt', index++);
            return activityIndexMap;
        },
        setColor(index) {
            //节点默认为白色
            //已完成的节点设置为绿色
            for (let i = 0; i < index; i++) {
                this.activities[i].color = '#0bbd87';
            }
            //当前节点设置为蓝色
            this.activities[index].type = 'primary';
        },
        setTime(item) {
            let recordMap = this.getNodeMap(item);
            this.activities.forEach(a => {
                let code = a.code;
                let time = recordMap.get(code);
                if (time) {
                    a.timestamp = time;
                }
            });
        },
        //获取已完成节点的最近时间map key-code value-完成时间
        getNodeMap(item) {
            let recordMap = new Map();
            //已完成节点
            let records = item.records;
            records.forEach(r => {
                let code = r.node.code;
                let time = r.endTime;
                let temp = recordMap.get(code);
                if (!temp || temp < time) {
                    recordMap.set(code, time);
                }
            });
            return recordMap;
        },
    },
    created() {
        this.dataForm.id = this.id;
    }
};
</script>
